var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-content"},[_c('div',{staticClass:"container-fluid"},[_c('Breadcrumb',{attrs:{"pgTitle":_vm.$t('nav.offlineCourses'),"pgIcon":'bx bx-video-off',"refs":_vm.refs,"addNew":_vm.addNew}}),_c('DataTable',{attrs:{"refs":_vm.refs,"colspan":10,"hasSearchFilter":true,"hasTimeFilter":true,"hasDateFilter":true,"hasStatusApproved":true,"hasStatusFilter":true,"hasLecturerFilter":true,"hasHashId":true,"hasMultiSelect":false,"hasBulkAction":true,"HasExport":true,"hasShowEntries":true,"lblSearch":_vm.$t('app.search'),"fields":{
                'id': 'id',
                'title': 'title',
                'period': 'period',
                'price': 'price',
                'time': 'time',
                'date': 'date',
                'paid': 'paid',
                'status': 'status'
            },"hasImage":true,"image":_vm.$t('view.course_image'),"image_style":'width: 40px;height: 40px',"hasTitle":true,"title":_vm.$t('view.course_title'),"hasPrice":true,"price":_vm.$t('view.course_price'),"hasPeriod":true,"hasDate":true,"hasTime":true,"hasStatus":true,"hasActions":true,"hasDeleteButton":true,"actionable":true,"sorDataTable":true},on:{"hasAddNewPermission":_vm.handlePermissoinChange}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }